/* global attachEvent,  */

// *******************************************************************************
// 1.0 - Close Modal
// *******************************************************************************

const gameSection = document.querySelector('.sc-game');

function clickHandler(offsetTop) {
  // eslint-disable-next-line no-restricted-globals
  scroll({
    top: offsetTop,
    behavior: 'smooth',
  });
}

attachEvent('.banner__scrolldown', 'click', (ev) => {
  const { offsetTop } = gameSection;
  clickHandler(offsetTop);
  ev.preventDefault();
});
