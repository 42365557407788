/* eslint-disable */
$(function () {
  var initSlide = 1;
  var startSlide = 2;
  var totalLandingTitles = $('.intro-title__slide').length + 1;
  window.setInterval(function () {
    $('.intro-title__slide--' + startSlide)
      .addClass('is-active')
      .siblings()
      .removeClass('is-active')
      .removeClass('is-prev');

    if (startSlide == initSlide) {
      $('.intro-title__slide--' + (totalLandingTitles - 1)).addClass('is-prev');
    } else {
      $('.intro-title__slide--' + startSlide)
        .prev()
        .addClass('is-prev');
    }

    startSlide++;

    if (startSlide == totalLandingTitles) {
      startSlide = 1;
    }
  }, 4000);
});
/* eslint-enable */
