/* global attachEvent */

// *******************************************************************************
// 1.0 - Close Modal
// *******************************************************************************

attachEvent('.modal__close', 'click', (ev) => {
  ev.preventDefault();
  ev.target.closest('.modal').classList.remove('is-open-modal');
});
