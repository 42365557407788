/* global Cookies, attachEvent */

const cookieElement = document.querySelectorAll('.cookie-banner');

if (cookieElement.length > 0) {
  if (!Cookies.get('cookie-banner-seen')) {
    cookieElement[0].classList.add('cookie-not-set');
  }

  // eslint-disable-next-line no-unused-vars
  attachEvent('.btn--cookie', 'click', (ev) => {
    ev.preventDefault();
    Cookies.set('cookie-banner-seen', 'cookie-set', { expires: 7 });
    cookieElement[0].classList.remove('cookie-not-set');
    setTimeout(() => { window.location.reload(); }, 500);
  });
}
