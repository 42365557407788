/* global attachEvent */

// *******************************************************************************
// 1.0 - Close Modal
// *******************************************************************************

attachEvent('.btn--quiz-submit', 'click', (ev) => {
  const gameModal = document.querySelector('.game-modal');
  gameModal.classList.add('is-open-modal');
  ev.preventDefault();
});
