/* eslint-disable */
$(function () {
  // ******
  // Teaser card sider
  // ******

  $('[data-slider="teaser-card-slider"]').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
});
/* eslint-enable */
